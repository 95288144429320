.reports-user-course {
    // shows while resetting a stream
    front-royal-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        margin: 0px;
    }

    .course-header {
        user-select: text !important;
        display: flex;
        justify-content: space-between;

        .title {
            position: relative;
            padding-left: 60px;
            min-height: 50px;
            display: table;
            word-wrap: break-word;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-left: 0;
                padding-right: 60px;
            }

            // Icon float to the left of the title
            .icon {
                position: absolute;
                display: block;
                left: 0px;
                top: 1px;
                height: 50px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    right: 0px;
                }
            }

            .title-inner {
                display: table-cell;
                vertical-align: middle;
            }

            .title-text {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 25px;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                line-height: 23px;
                margin: 0;
            }

            .title-subtext {
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                margin: 0;

                .header {
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    font-size: 15px;
                    line-height: 18px;
                }

                .text {
                    font-weight: $FONT_WEIGHT_NORMAL;
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }

        .stream-status-info-container {
            display: flex;

            .locked-status-container,
            .authenticate-status-container {
                margin-right: 20px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-left: 20px;
                    margin-right: 0px;
                }
            }
        }

        .authenticated {
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 12px;
            color: $COLOR_V3_BEIGE_DARKER;
            line-height: 14px;
        }

        .completed {
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 15px;
            color: $COLOR_V3_BEIGE_DARKER;
            line-height: 17px;
        }

        .danger-button.unstyled-button {
            color: $COLOR_V3_RED;
            font-size: 12px;
            line-height: 14px;
            display: block;
        }

        .export-csv {
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 12px;
            color: $COLOR_V3_CORAL;
            line-height: 14px;
        }
    }

    .course-table {
        font-size: 15px;
        line-height: 15px;
        margin-top: -6px; // make room for "assessment" group label

        &.invisible {
            visibility: hidden;
        }

        .assessment-badge {
            display: inline-block;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            background-color: $COLOR_V3_BEIGE;
            border: 0px solid transparent;
            border-radius: 4px;
            padding: 2px 4px;
            margin-top: -2px;
            margin-bottom: -2px;
            margin-left: 5px;
        }

        // table formatting
        thead {
            tr {
                th {
                    border: none;
                    padding: 5px;
                }

                &:first-of-type {
                    th {
                        border: none;
                    }
                }
            }
        }

        tbody {
            tr {
                user-select: text !important;

                &:hover {
                    background-color: $COLOR_V3_BEIGE_LIGHT;
                }
                &.completed {
                    th,
                    td {
                        color: $COLOR_V3_EGGPLANT;
                    }
                }

                th {
                    padding: 5px;
                    a {
                        color: $COLOR_V3_CORAL;
                    }
                }
                td {
                    padding: 5px;
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                }
            }
        }
    }
}

report-date-range-input {
    $inputHeight: 31px;

    position: relative;
    display: block;

    .date-button {
        cursor: pointer;

        &.focused {
            background-color: $COLOR_V3_BEIGE_MIDDARK !important;
        }
    }

    .calendar-icon {
        width: 16px;
        height: 16px;
        margin-top: -4px;

        // rtl-language overrides
        html[dir="rtl"] & {
            margin-top: 6px;
            margin-left: 5px;
        }
    }

    .date-range-dropdown {
        font-weight: $FONT_WEIGHT_NORMAL;
        position: absolute;
        right: 0px;
        top: calc(100% + 5px);
        border: 1px solid $COLOR_V3_BEIGE_BEYOND_DARK;
        background-color: $COLOR_V3_BEIGE_LIGHTER;
        border-radius: 3px;
        padding: 15px;
        white-space: nowrap;
        z-index: 2;

        .inline {
            display: inline-block;
            vertical-align: top;
            height: $inputHeight;
            line-height: $inputHeight;
        }

        .selectize-input,
        input,
        .styled-as-input {
            background-color: $COLOR_V3_WHITE !important;
        }

        .input-wrapper {
            min-width: auto !important;
            margin-right: 5px !important;
            min-height: auto !important;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-right: 0 !important;
                margin-left: 5px !important;
            }
        }

        .input-wrapper.date-range-type {
            min-width: 104px !important;
        }

        .input-wrapper.date-range-value {
            width: 40px;
            input {
                padding: 5px;
            }
        }

        .input-wrapper.date {
            width: auto;
            cursor: pointer;
        }

        button.apply {
            margin-left: 5px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-left: 0;
                margin-right: 5px;
            }
        }
    }
}

.js-plotly-plot {
    // .plot-container.plotly {
    //     margin-top: -80px;
    // }

    .plotly {
        [data-title]:after {
            background: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK !important;
        }
        [data-title]:before {
            border-bottom-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK !important;
        }

        .modebar-btn {
            path {
                fill: $COLOR_V3_BEIGE_MIDDARK !important;
            }

            &.active,
            &:hover {
                path {
                    fill: $COLOR_V3_BEIGE_BEYOND_DARK !important;
                }
            }
        }
    }
}

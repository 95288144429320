.selectize-control.plugin-no-delete {
    // prevent the blinking cursor from showing up
    > .selectize-input {
        cursor: pointer !important;

        > input {
            display: none !important;
        }
    }
}

.reports.dashboard-container reports-user {
    display: block;

    // make sure not to interfere with the spinner
    // inside of reports-user-course
    front-royal-spinner.empty-placeholder-section {
        margin: 125px 0px;
    }

    .export-csv {
        font-weight: $FONT_WEIGHT_NORMAL;
        font-size: 15px;
        color: $COLOR_V3_CORAL;
        line-height: 30px;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .report-wrapper {
        background-color: $COLOR_V3_WHITE;
        border: 0px solid transparent;
        border-radius: 4px;
        margin-bottom: 25px;

        .empty-placeholder-section {
            padding: 60px 0px 110px;
        }
    }

    .btn-white {
        display: block;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        border: none;
        font-size: 20px;
        line-height: 20px;
        padding: 20px 25px;

        &:hover {
            background-color: $COLOR_V3_BEIGE_LIGHT;
        }
    }

    .empty-placeholder-section {
        padding: 150px 0px;

        .header {
            font-size: 25px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }

        .message {
            width: 519px;
            margin: 0 auto;
        }
    }

    .view-activity-report,
    .not-started {
        margin: 0 auto;
        margin-top: 38px;
        max-width: 300px;
        white-space: normal;
    }

    .user-info {
        user-select: text !important;

        p {
            margin-bottom: 0px;
        }

        .name-section {
            display: inline-block;
        }

        .name {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 25px;
            color: $COLOR_V3_EGGPLANT;
            line-height: 30px;
            display: inline-block;
            margin-right: 10px;
        }

        .email {
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 25px;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            line-height: 30px;
        }

        .registered {
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 25px;
            color: $COLOR_V3_EGGPLANT;
            line-height: 30px;
        }

        .subtext {
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 15px;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            line-height: 18px;
        }
    }

    .plotly-section {
        margin-top: 0px !important;
    }
}

.reports.dashboard-container {
    width: auto !important;
    max-width: 100% !important;
    // Generic empty placeholder section
    .empty-placeholder-section {
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        text-align: center;
        padding: 115px 0px;
        h2 {
            line-height: 1.4em;
        }
    }

    // Apply and Clear button styles
    button.apply,
    button.clear {
        height: 31px;
        padding: 8px 10px 7px 10px;
        font-size: 15px;
        line-height: 100%; // vertical align
        border-radius: 4px;
        font-weight: $FONT_WEIGHT_NORMAL;
        box-shadow: none !important;
    }
    button.apply {
        border: 1px solid $COLOR_V3_CORAL;
    }
    button.clear {
        border: 1px solid $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    // have to restrict this to divs in order to use nth-of-type
    div.section {
        background: $COLOR_V3_WHITE;
        border-radius: 4px;
        display: block;
        padding: 15px 15px 15px;
        margin-top: 25px;
        position: relative; //required for z-index support

        // put higher sections above lower (to supoort dropdowns, specifically date dropdown)
        @for $i from 1 through 10 {
            &:nth-of-type(#{$i}) {
                z-index: (#{10 - $i});
            }
        }
    }

    .export-csv {
        a {
            font-size: 12px;
            color: $COLOR_V3_CORAL;
        }
    }

    // table formatting
    thead {
        tr {
            &:first-of-type {
                th {
                    text-align: center;
                    border: none;
                    padding: 0;
                    cursor: default;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        text-align: center;
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            th {
                border-top: none;
                cursor: pointer;

                // rtl-language overrides
                html[dir="rtl"] & {
                    text-align: right;
                }

                &:hover {
                    background-color: $COLOR_V3_BEIGE_LIGHT;
                }
            }
        }
    }

    // graph formatting
    .js-plotly-plot .plotly,
    .js-plotly-plot .plotly div {
        // rtl-language overrides
        html[dir="rtl"] & {
            direction: rtl;
        }
    }

    .pull-right {
        // rtl-language overrides
        html[dir="rtl"] & {
            float: left !important;
        }
    }
}

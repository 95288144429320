@mixin set-bs-dropdown-colors($borderColor, $bgColor) {
    .datetimepicker {
        border: 1px solid $borderColor;
        border-radius: 4px;
        background-color: $bgColor;
    }
}

.bs-dropdown-reset {
    .dropdown-menu {
        background-color: transparent;
        border-width: 0px;
        border-radius: 0px;
        box-shadow: none;
    }
}

.bs-dropdown-centered-below {
    .dropdown-menu {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.datetimepicker {
    .glyphicon-arrow-left {
        &:before {
            @include font-awesome-icon("\f0a8"); // fa-arrow-circle-left
        }
    }

    .glyphicon-arrow-right {
        &:before {
            @include font-awesome-icon("\f0a9"); // fa-arrow-circle-right
        }
    }
}

.beige-datetimepicker {
    @include set-bs-dropdown-colors($COLOR_V3_BEIGE_BEYOND_DARK, $COLOR_V3_BEIGE);
}

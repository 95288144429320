.report-tabs.btn-group {
    a.btn.btn-default {
        font-size: 22px;
        line-height: 40px;
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: transparent;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        border: 2px solid $COLOR_V3_BEIGE_BEYOND_DARK;

        &:first-of-type {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-right-width: 0px;

            // rtl-language overrides
            html[dir="rtl"] & {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-left-width: 0px;
                border-right-width: 2px;
            }
        }

        &:last-of-type {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-left-width: 0px;

            // rtl-language overrides
            html[dir="rtl"] & {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-right-width: 0px;
                border-left-width: 2px;
            }
        }

        &:hover {
            background-color: $COLOR_V3_BEIGE_MEDIUM;
        }

        &.active {
            background-color: $COLOR_V3_BEIGE_DARKER;
            color: $COLOR_V3_WHITE;
            &:not(:active) {
                box-shadow: none;
            }
        }

        &.selected {
            color: $COLOR_V3_WHITE;
            background-color: $COLOR_V3_BEIGE_BEYOND_DARK;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }
    }
}

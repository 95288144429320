.reports.dashboard-container reports-activity {
    // quick search
    .search-form {
        display: inline-block;
        vertical-align: top;
        input {
            font-size: 13px;
            border-radius: 15px;
        }
        .btn {
            line-height: 30px;
            font-size: 13px;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            border-color: $COLOR_V3_BEIGE_BEYOND_DARK;
            padding: 0px 12px;
            border-radius: 15px;

            &:hover {
                background-color: $COLOR_V3_BEIGE_LIGHT;
            }
        }
    }

    .export-csv {
        line-height: 30px;
        .export-link {
            margin-right: 10px;

            // rtl-language overrides
            html[dir="rtl"] & {
                margin-right: 0;
                margin-left: 10px;
            }
        }
    }

    .limit-popover {
        transform: translateZ(0); // fix chrome visual glitch
        text-align: right;
        margin-left: -10px;

        p {
            cursor: pointer;
            &:hover {
                color: $COLOR_V3_CORAL;
            }
            &.active {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                color: $COLOR_V3_EGGPLANT;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .arrow {
            border-bottom-color: rgba(0, 0, 0, 0.1);
        }
    }

    .users-table {
        font-size: 12px;
        margin-top: 10px;

        // column sorting
        .sort-asc {
            &:before {
                @include font-awesome-icon("\f0d8"); // fa-caret-up
                margin-right: 5px;
            }
        }

        .sort-desc {
            &:before {
                @include font-awesome-icon("\f0d7"); // fa-caret-down
                margin-right: 5px;
            }
        }

        tbody {
            tr {
                user-select: text !important;

                &:hover {
                    background-color: $COLOR_V3_BEIGE_LIGHT;
                }

                th,
                td {
                    a {
                        color: $COLOR_V3_CORAL;
                    }
                }
                td {
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                }
            }
        }
    }
}

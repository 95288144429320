.reports.dashboard-container .filter-bar {
    margin-bottom: -6px;

    .input-wrapper {
        // special overrides for courses filters
        &.CourseFilter,
        &.CourseCompletedFilter,
        &.CourseStartedFilter {
            // wider dropdown for the wide labels
            .selectize-dropdown {
                min-width: 350px;
            }

            // truncate selected item titles
            &.has-value .selectize-control.multi .selectize-input .item {
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        // special override for width of email name filter when in spanish
        &.EmailNameFilter input {
            html:lang(es) & {
                min-width: 200px;
            }
        }

        &.has-value {
            // labels for each filter type
            // Note: we have to manually copy these into here from the reports-*.json 'filter_label_*' entries
            &.compare {
                @include filter-label-content("Compare");
                html:lang(es) & {
                    @include filter-label-content("Comparar");
                }
                html:lang(zh) & {
                    @include filter-label-content("比较");
                }
                html:lang(ar) & {
                    @include filter-label-content("مقارنة");
                }
            }
            &.InstitutionFilter {
                @include filter-label-content("Institutions");
                html:lang(es) & {
                    @include filter-label-content("Instituciones");
                }
                html:lang(zh) & {
                    @include filter-label-content("机构");
                }
                html:lang(ar) & {
                    @include filter-label-content("مؤسسة");
                }
            }
            &.SignUpCodeFilter {
                @include filter-label-content("Sign Up Codes");
                html:lang(es) & {
                    @include filter-label-content("Códigos de registro");
                }
                html:lang(zh) & {
                    @include filter-label-content("注册代码");
                }
                html:lang(ar) & {
                    @include filter-label-content("رمز التسجيل");
                }
            }
            &.RoleFilter {
                @include filter-label-content("Roles");
                html:lang(es) & {
                    @include filter-label-content("Funciones");
                }
                html:lang(zh) & {
                    @include filter-label-content("角色");
                }
                html:lang(ar) & {
                    @include filter-label-content("وظيفة");
                }
            }
            &.GroupFilter {
                @include filter-label-content("Groups");
                html:lang(es) & {
                    @include filter-label-content("Grupos");
                }
                html:lang(zh) & {
                    @include filter-label-content("组");
                }
                html:lang(ar) & {
                    @include filter-label-content("المجموعات");
                }
            }
            &.CourseFilter {
                @include filter-label-content("Courses");
                html:lang(es) & {
                    @include filter-label-content("Cursos");
                }
                html:lang(zh) & {
                    @include filter-label-content("课程");
                }
            }
            &.CourseCompletedFilter {
                @include filter-label-content("Courses Completed");
                html:lang(es) & {
                    @include filter-label-content("Cursos completados");
                }
                html:lang(zh) & {
                    @include filter-label-content("已完成课程");
                }
                html:lang(ar) & {
                    @include filter-label-content("الدورات المكتملة");
                }
            }
            &.CourseStartedFilter {
                @include filter-label-content("Courses Started");
                html:lang(es) & {
                    @include filter-label-content("Cursos iniciados");
                }
                html:lang(zh) & {
                    @include filter-label-content("已开始课程");
                }
                html:lang(ar) & {
                    @include filter-label-content("الدورات التي تم البدء فيها");
                }
            }
            &.CohortFilter {
                @include filter-label-content("Cohorts");
                html:lang(es) & {
                    @include filter-label-content("Cohorts");
                }
                html:lang(zh) & {
                    @include filter-label-content("同批人");
                }
                html:lang(ar) & {
                    @include filter-label-content("الأفواج");
                }
            }
            &.LessonLocaleFilter {
                @include filter-label-content("Locales");
                html:lang(es) & {
                    @include filter-label-content("Idiomas");
                }
                html:lang(zh) & {
                    @include filter-label-content("区域设置");
                }
            }
            &.ClientTypeFilter {
                @include filter-label-content("Client");
                html:lang(es) & {
                    @include filter-label-content("Cliente");
                }
                html:lang(zh) & {
                    @include filter-label-content("客户");
                }
                html:lang(ar) & {
                    @include filter-label-content("الجهاز المستخدم");
                }
            }
        }
    }
}
